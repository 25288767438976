import { Outlet, Link } from "react-router-dom";

import React, { useEffect, useState } from "react";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Headerhome = () => {
  return (
    <>
      <header className="home_1_update">
        <div className="header_top">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-lg-12">
                <div className="info_wrapper">
                  <div className="contact_info">
                    <ul className="list-unstyled">
                      <li>
                        <i className="flaticon-phone-receiver" />
                        {companynumber}
                      </li>
                      <li>
                        <i className="flaticon-mail-black-envelope-symbol" />
                        {companyemail}
                      </li>
                    </ul>
                  </div>
                  <div className="contact_info">
                    <ul className="list-unstyled">
                      <li>
                        <Link to="/Privacy" style={{ color: "white" }}>
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link to="/Terms" style={{ color: "white" }}>
                          Terms
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="edu_nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light bg-faded">
              <Link className="navbar-brand" to="/Home">
                <img
                  src="images/logo.png"
                  style={{ height: "100px" }}
                  alt="logo"
                />
              </Link>
              <div
                className="collapse navbar-collapse main-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav nav lavalamp ml-auto menu">
                  <li className="nav-item">
                    <Link to="/Home" className="nav-link ">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/About" className="nav-link">
                      About
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/Services" className="nav-link">
                      Services We Provide
                    </Link>
                    <ul className="navbar-nav nav mx-auto">
                      <li className="nav-item">
                        <Link to="/Air" className="nav-link">
                          Air Freight
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/Sea" className="nav-link">
                          Sea Freight
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/Road" className="nav-link">
                          Road Freight
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/Customs" className="nav-link">
                          Customs Clearance
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/Warehousing" className="nav-link">
                          Warehousing
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/Project" className="nav-link">
                          Project Cargo
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="#" className="nav-link dropdown_icon">
                          Trading Solutions
                        </Link>
                        <ul className="navbar-nav nav mx-auto">
                          <li className="nav-item">
                            <Link to="/Trading#Apparel" className="nav-link">
                              Apparel
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/Trading#Fruits" className="nav-link">
                            Fruits & Vegetables
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/Trading#Food" className="nav-link">
                            Food & Beverages
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/Trading#Chemicals" className="nav-link">
                            Chemicals & Petrochemicals
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link to="/Contact" className="nav-link">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mr-auto search_area ">
                <Link
                  to="/Getquote"
                  className="cart_btn"
                  style={{
                    background: "#162640",
                    fontWeight: 600,
                    borderRadius: 0,
                    color: "#ffffff",
                    display: "inline-block",
                    fontSize: "14px",
                    textTransform: "uppercase",
                    textDecoration: "none",
                    height: "36px",
                    lineHeight: "36px",
                    padding: "0 20px",
                    WebkitTransition: "all 0.3s ease-in-out",
                    transition: "all 0.3s ease-in-out",
                  }}
                >
                  Request For Quote
                </Link>
              </div>
            </nav>
            {/* END NAVBAR */}
          </div>
        </div>
        {/*==================
        Slider
    ===================*/}
        <div className="rev_slider_wrapper">
          <div id="rev_slider_1" className="w3-banner jarallax">
            <ul>
              <li
                data-index="rs-1706"
                data-transition="fade"
                data-slotamount={7}
                data-hideafterloop={0}
                data-hideslideonmobile="off"
                data-easein="default"
                data-easeout="default"
                data-masterspeed={1000}
                data-rotate={0}
                data-saveperformance="off"
                data-title="Slide"
                data-param1
                data-param2
                data-param3
                data-param4
                data-param5
                data-param6
                data-param7
                data-param8
                data-param9
                data-param10
                data-description
              >
                <div className="slider-overlay" />
                {/* SLIDE'S MAIN BACKGROUND IMAGE */}
                <video
                  className="img-fluid"
                  autoPlay
                  muted
                  loop
                  style={{ zIndex: "1" }}
                >
                  <source src="images/video.mp4" type="video/mp4" />
                </video>
                <img src="images/banner.jpg" alt="Sky" className="rev-slidebg" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax={10} data-no-retina />
                {/* LAYER NR. 1 */}
                <div
                  className="tp-caption font-lora sfb tp-resizeme letter-space-5 h-p"
                  data-x="['left','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['-150','-280','-250','-200']"
                  data-fontsize="['20','40','32','17']"
                  data-lineheight="['70','80','70','70']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[{"from":"z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;","speed":400,"to":"o:1;","delay":100,"split":"chars","splitdelay":0.05,"ease":"Power3.easeInOut"},{"delay":"wait","speed":100,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                  style={{
                    zIndex: 7,
                    color: "#fff",
                    fontFamily: '"Rubik", sans-serif',
                    maxWidth: "auto",
                    maxHeight: "auto",
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                  }}
                >
                  The Goal Of Education Is The Advancement Of Knowledge
                </div>
              </li>
              <li
                data-index="rs-1708"
                data-transition="fade"
                data-slotamount={7}
                data-hideafterloop={0}
                data-hideslideonmobile="off"
                data-easein="default"
                data-easeout="default"
                data-masterspeed={1000}
                data-rotate={0}
                data-saveperformance="off"
                data-title="Slide"
                data-param1
                data-param2
                data-param3
                data-param4
                data-param5
                data-param6
                data-param7
                data-param8
                data-param9
                data-param10
                data-description
              >
                <div className="slider-overlay" />

                {/* LAYER NR. 1 */}
                <div
                  className="tp-caption font-lora sfb tp-resizeme letter-space-5 h-p"
                  data-x="['left','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['-150','-280','-250','-200']"
                  data-fontsize="['20','40','32','17']"
                  data-lineheight="['70','80','70','70']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[{"from":"z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;","speed":400,"to":"o:1;","delay":100,"split":"chars","splitdelay":0.05,"ease":"Power3.easeInOut"},{"delay":"wait","speed":100,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                  style={{
                    zIndex: 7,
                    color: "#fff",
                    fontFamily: '"Rubik", sans-serif',
                    maxWidth: "auto",
                    maxHeight: "auto",
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                  }}
                >
                  The Goal Of Education Is The Advancement Of Knowledge
                </div>
                {/* LAYER NR. 2 */}
                <div
                  className="tp-caption NotGeneric-Title   tp-resizeme"
                  id="slide-3045-layer-11"
                  data-x="['left','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['-70','-140','-140','-120']"
                  data-fontsize="['65','120','75','50']"
                  data-lineheight="['70','120','70','70']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[{"from":"x:[105%];z:0;rX:45deg;rY:0deg;rZ:90deg;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":2000,"to":"o:1;","delay":1000,"split":"chars","splitdelay":0.05,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[10,10,10,10]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[10,10,10,10]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: 5,
                    fontFamily: '"Roboto", sans-serif',
                    fontWeight: 700,
                    whiteSpace: "nowrap",
                    textTransform: "left",
                  }}
                >
                  Take The First Step To
                </div>
                {/* LAYER NR.3 */}
                <div
                  className="tp-caption NotGeneric-Title   tp-resizeme"
                  id="slide-3045-layer-12"
                  data-x="['left','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['10','0','-10','-40']"
                  data-fontsize="['65','120','70','50']"
                  data-lineheight="['70','120','70','70']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[{"from":"x:[105%];z:0;rX:45deg;rY:0deg;rZ:90deg;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":2000,"to":"o:1;","delay":1000,"split":"chars","splitdelay":0.05,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[10,10,10,10]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[10,10,10,10]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: 5,
                    fontFamily: '"Roboto", sans-serif',
                    fontWeight: 700,
                    whiteSpace: "nowrap",
                    textTransform: "left",
                  }}
                >
                  Knowledge With Us.
                </div>
                {/* LAYER NR. 4 */}
                <div
                  className="tp-caption rev-btn rev-btn left_btn"
                  id="slide-2939-layer13"
                  data-x="['left','left','left','left']"
                  data-hoffset="['0','500','420','280']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['125','220','190','100']"
                  data-fontsize="['14','14','10','8']"
                  data-lineheight="['34','34','30','20']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="button"
                  data-actions='[{"event":"click","action":"jumptoslide","slide":"rs-2939","delay":""}]'
                  data-responsive_offset="on"
                  data-responsive="off"
                  data-frames='[{"from":"x:-50px;opacity:0;","speed":1000,"to":"o:1;","delay":1750,"ease":"Power2.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:#ffffff;bg:#ff5a2c;bw:2px 2px 2px 2px;"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[12,12,8,8]"
                  data-paddingright="[40,40,30,25]"
                  data-paddingbottom="[12,12,8,8]"
                  data-paddingleft="[40,40,30,25]"
                  style={{
                    zIndex: 14,
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    color: "#ffffff",
                    fontFamily: "Rubik",
                    textTransform: "uppercase",
                    backgroundColor: "#ff5a2c",
                    borderColor: "rgba(0, 0, 0, 1.00)",
                    borderWidth: "2px",
                    borderRadius: "3px",
                  }}
                >
                  Get Started Now
                </div>
                {/* LAYER NR. 5 */}
                <div
                  className="tp-caption rev-btn rev-btn right-btn"
                  id="slide-2939-layer-15"
                  data-x="['left','left','left','left']"
                  data-hoffset="['250','-60','90','-70']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['125','220','190','100']"
                  data-fontsize="['14','14','10','8']"
                  data-lineheight="['34','34','30','20']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="button"
                  data-actions='[{"event":"click","action":"jumptoslide","slide":"rs-2939","delay":""}]'
                  data-responsive_offset="on"
                  data-responsive="off"
                  data-frames='[{"from":"x:-50px;opacity:0;","speed":1000,"to":"o:1;","delay":1750,"ease":"Power2.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:#fff;bg:#ff5a2c;bw:2px 2px 2px 2px; "}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[12,12,8,8]"
                  data-paddingright="[40,40,30,25]"
                  data-paddingbottom="[12,12,8,8]"
                  data-paddingleft="[40,40,30,25]"
                  style={{
                    zIndex: 14,
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    color: "#ffffff",
                    fontFamily: "Rubik",
                    textTransform: "uppercase",
                    backgroundColor: "#092ace",
                    letterSpacing: "1px",
                    borderRadius: "3px",
                  }}
                >
                  View Courses
                </div>
              </li>
            </ul>
            {/* END SLIDES LIST */}
          </div>
          {/* END SLIDER CONTAINER */}
        </div>
        {/* END SLIDER CONTAINER WRAPPER */}
      </header>
    </>
  );
};

export default Headerhome;
