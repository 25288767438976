import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Sea() {
  return (
    <>
      <Header />
      <header className="header_inner about_page">
        <div className="intro_wrapper">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-8 col-lg-8">
                <div className="intro_text">
                  <h1>Road Freight</h1>
                  <div className="pages_links">
                    <Link to="/Home" title>
                      Home
                    </Link>
                    <Link title className="active">
                      Road Freight
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="blog_wrapper" id="courses_details_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-8 col-lg-8">
              <div className="courses_details">
                <div className="single-curses-contert">
                  <h2>Road Freight</h2>
                  <div className="mt-4"></div>
                  <div className="details-img-bxo">
                    <img src="images/road.jpg" alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="courses_tab_wrapper">
                  {/* Tab panes */}
                  <div className="tab_contents tab-content">
                    <div
                      className="tab-pane fade in active show"
                      id="information"
                    >
                      <p>
                        Our extensive road networks stand as a testament to
                        well-established and intricately woven pathways that
                        crisscross regions across the India. Our road systems have been
                        carefully cultivated to seamlessly integrate within
                        these diverse geographical regions. The result is a
                        network that transcends borders, ensuring efficient
                        connectivity and smooth flow of goods.
                        <br />
                        <br />
                        At the heart of our operations lies a commitment to
                        reliability. Our domestic road flows are meticulously
                        planned and executed, with a keen focus on safety,
                        sustainability, and cost-effectiveness. We understand
                        that the movement of goods along these routes is not
                        merely a matter of transportation; it's a vital link in
                        the supply chain that demands the utmost precision. From
                        state-of-the-art tracking systems to rigorous safety
                        protocols, we leave no stone unturned to guarantee the
                        security and timely delivery of your shipments. This
                        dedication translates into a transportation partner you
                        can trust, time and time again.
                        <br />
                        The scope of our capabilities extends far beyond the
                        physical roads themselves. We're equipped to handle a
                        diverse array of cargo types, showcasing our versatility
                        and adaptability. Whether it's the delicate transport of
                        pharmaceuticals requiring temperature-controlled
                        environments, the responsible carriage of chemicals, the
                        efficient distribution of consumer goods to meet market
                        demands, or the safe and timely delivery of vehicles and
                        heavy machinery, our expertise spans the spectrum. With
                        a comprehensive understanding of the unique requirements
                        that each category demands, we tailor our services to
                        ensure your goods reach their destination in prime
                        condition, on schedule, and with the utmost care.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            {/*End Blog Siderbar Left*/}
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 blog_wrapper_right ">
              <div className="blog-right-items">
                <div className="courses_features widget_single">
                  <div className="items-title">
                    <h3 className="title">All Services</h3>
                  </div>
                  <div className="features_items">
                    <ul className="list-unstyled">
                      <li>
                        <Link to="/Air" title>
                          <i className="fa fa-plane" /> Air Freight{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Sea" title>
                          <i className="fa fa-ship" /> Sea Freight{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Road" title>
                          <i className="fa fa-truck" /> Road Freight{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Customs" title>
                          <i className="fa fa-check-circle" /> Customs Clearance{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Warehousing" title>
                          <i className="fa fa-warehouse" /> Warehousing{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Project" title>
                          <i className="fa fa-box" /> Project Cargo{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <img
                    src="images/shapes/testimonial_2_shpe_2.png"
                    alt=""
                    className="courses_feaures_shpe"
                  />
                </div>

                <div className="archives widget_single">
                  <div className="items-title">
                    <h3 className="title">Trading Services</h3>
                  </div>
                  <div className="archives-items">
                    <ul className="list-unstyled">
                      <li>
                        <Link to="/Trading#Apparel" title>
                          Apparel{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Trading#Fruits" title>
                          Fruits & Vegetables{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Trading#Food" title>
                          Food & Beverages{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Trading#Chemicals" title>
                          Chemicals & Petrochemicals{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>{" "}
            {/* End Right Sidebar*/}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
