import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      <header className="header_inner about_page">
      <div className="intro_wrapper">
          <div className="container">  
            <div className="row">        
              <div className="col-sm-12 col-md-8 col-lg-8">
                <div className="intro_text">
                  <h1>Contact Us</h1>
                  <div className="pages_links">
                    <Link to="/Home" title>Home</Link>
                    <Link title className="active">Contact Us</Link>
                  </div>
                </div>
              </div>              
            </div>
          </div> 
        </div> </header>
       
        <section className="contact_info_wrapper">
          <div className="container">  
            <div className="row">  
              <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                <div className="contact_info">
                  <h3 className="title">Contact Details</h3>
                  <div className="event_location_info">                  
                    <ul className="list-unstyled">
                      <li>
                        <h4 className="info_title">Address : </h4>
                        <ul className="list-unstyled">
                          <li>{companyaddress} </li>
                          
                        </ul>
                      </li>
                      <li>
                        <h4 className="info_title">Phone Number :</h4>
                        <ul className="list-unstyled">
                          <li>{companynumber}</li>
                    
                        </ul>
                      </li>
                      <li>
                        <h4 className="info_title">Our E-mail :</h4>
                        <ul className="list-unstyled">
                          <li>{companyemail}</li>                                    
                        </ul>
                      </li>                      
                    </ul>
                    <img src="images/banner/map_shape.png" alt="" className="contact__info_shpae" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                <div className="contact_form_wrapper">
                  <h3 className="title">Get In Touch</h3>
                  <div className="leave_comment">
                    <div className="contact_form">
                    <form
                    action="/php/thankyou-contact.php "
                    method="post"
                    onSubmit={(event) => handleSubmit(event)}
                  >
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                      <input
                        onChange={(event) => handleFnameChange(event)}
                        type="text"
                        className="form-control"
                        id="fname"
                        name="fname"
                        value={fname}
                        placeholder="Enter your first name"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                      />
                      <span />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                      <input
                        onChange={(event) => handlelnameChange(event)}
                        type="text" className="form-control"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="lname"
                        name="lname"
                        value={lname}
                        placeholder="Enter your last name"
                      />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                      <input
                        onChange={(event) => handleemailChange(event)}
                        type="email" className="form-control"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="email"
                        name="email"
                        value={email}
                        placeholder="Enter your Email"
                      />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                      <input
                        onChange={(event) => handlesubjectChange(event)}
                        type="text" className="form-control"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="subject"
                        name="subject"
                        value={subject}
                        placeholder="Enter subject"
                      />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                      <textarea className="form-control"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="message"
                        name="message"
                        rows={1}
                        placeholder="Enter Message"
                        defaultValue={""}
                        value={message}
                        onChange={(event) => handlemessageChange(event)}
                      />
                      </div>
                    </div>
                    <div className="col-12">
                      <button name="submit" className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                    </div>
                  </div>
                </form>
              <h1>{result}</h1>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </section> {/* Contact Info Wrappper*/}
        <section className="contact_map">
          <div className="container-fluid">  
            <div className="row">                  
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mr-auto p-0">
                <h2 className="disabled">Google Map</h2>
                <div className="google_map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d120560.06085829664!2d72.979762!3d19.216946!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b92abc14627f%3A0x67321c4ebf19795e!2sLake%20City%20Mall!5e0!3m2!1sen!2sin!4v1691145152618!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>                 
                </div>
              </div>
            </div>
          </div>
        </section>


      <Footer />
    </>
  );
}
