import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Sea() {
  return (
    <>
      <Header />
      <header className="header_inner about_page">
        <div className="intro_wrapper">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-8 col-lg-8">
                <div className="intro_text">
                  <h1>Warehousing</h1>
                  <div className="pages_links">
                    <Link to="/Home" title>
                      Home
                    </Link>
                    <Link title className="active">
                      Warehousing
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="blog_wrapper" id="courses_details_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-8 col-lg-8">
              <div className="courses_details">
                <div className="single-curses-contert">
                  <h2>Warehousing</h2>
                  <div className="mt-4"></div>
                  <div className="details-img-bxo">
                    <img
                      src="images/warehousing.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="courses_tab_wrapper">
                  {/* Tab panes */}
                  <div className="tab_contents tab-content">
                    <div
                      className="tab-pane fade in active show"
                      id="information"
                    >
                      <p>
                        Devoted to delivering an unparalleled level of service,
                        we take upon ourselves the intricate task of
                        orchestrating, from inception to fruition, the intricate
                        dance of supply chain dynamics. With an unwavering focus
                        on efficiency, we meticulously handle the meticulous
                        planning, seamless execution, and vigilant control of
                        each operation. Our aim is crystal clear: to ensure that
                        every facet of the supply chain operates harmoniously,
                        optimizing resources and time, and ultimately enhancing
                        your overall business performance.
                        <br />
                        <br />
                        A cornerstone of our comprehensive approach lies in our
                        extensive network of warehouses strategically positioned
                        across key geographic points. These warehouses aren't
                        just physical spaces; they are pivotal hubs of
                        synchronized activity where goods find temporary repose
                        before embarking on their onward journey. Our network is
                        carefully designed and strategically distributed to
                        ensure maximum coverage and accessibility, allowing us
                        to cater to the diverse needs of our clientele. Whether
                        it's storage, consolidation, or distribution, our
                        warehouses stand as pillars of reliability, ready to
                        accommodate and serve.
                        <br />
                        <br />
                        In a landscape where the global market is characterized
                        by swift fluctuations and demands are ever-evolving, our
                        network of warehouses acts as a responsive and agile
                        force. By offering dynamic solutions that adapt to the
                        changing demands of your business, we empower you with
                        the means to flexibly allocate resources, optimize
                        inventory management, and swiftly respond to market
                        shifts. This multi-pronged approach, combining
                        meticulous supply chain oversight with a strategically
                        positioned warehouse network, stands as a testament to
                        our commitment to your success.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            {/*End Blog Siderbar Left*/}
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 blog_wrapper_right ">
              <div className="blog-right-items">
                <div className="courses_features widget_single">
                  <div className="items-title">
                    <h3 className="title">All Services</h3>
                  </div>
                  <div className="features_items">
                    <ul className="list-unstyled">
                      <li>
                        <Link to="/Air" title>
                          <i className="fa fa-plane" /> Air Freight{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Sea" title>
                          <i className="fa fa-ship" /> Sea Freight{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Road" title>
                          <i className="fa fa-truck" /> Road Freight{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Customs" title>
                          <i className="fa fa-check-circle" /> Customs Clearance{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Warehousing" title>
                          <i className="fa fa-warehouse" /> Warehousing{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Project" title>
                          <i className="fa fa-box" /> Project Cargo{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <img
                    src="images/shapes/testimonial_2_shpe_2.png"
                    alt=""
                    className="courses_feaures_shpe"
                  />
                </div>

                <div className="archives widget_single">
                  <div className="items-title">
                    <h3 className="title">Trading Services</h3>
                  </div>
                  <div className="archives-items">
                    <ul className="list-unstyled">
                      <li>
                        <Link to="/Trading#Apparel" title>
                          Apparel{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Trading#Fruits" title>
                          Fruits & Vegetables{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Trading#Food" title>
                          Food & Beverages{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Trading#Chemicals" title>
                          Chemicals & Petrochemicals{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>{" "}
            {/* End Right Sidebar*/}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
