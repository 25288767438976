import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Sea() {
  return (
    <>
      <Header />
      <header className="header_inner about_page">
        <div className="intro_wrapper">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-8 col-lg-8">
                <div className="intro_text">
                  <h1>Trading Services</h1>
                  <div className="pages_links">
                    <Link to="/Home" title>
                      Home
                    </Link>
                    <Link title className="active">
                    Trading Services
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="events-area">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="event_title_wrapper">
                <div className="sub_title">
                  <h2>Our Trading Services</h2>
                  <p>
                    We have earned the position of a reliable transportation
                    collaborator for numerous esteemed global enterprises,
                    conscientiously transporting numerous cargoes and
                    commodities with unparalleled diligence. Our services
                    encompass comprehensive solutions for trading needs.
                  </p>
                </div>
                {/* ends: .section-header */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 events_full_box">
              <div className="events_single" id="apparel">
                <div className="event_banner">
                  <Link>
                    <img
                      src="images/apparel.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </Link>
                </div>
                <div className="event_info">
                  <h3>
                    <Link title> Apparel</Link>
                  </h3>
                  <div className="mt-4"></div>
                  <p>
                    The fashion and clothing sector is undergoing swift changes,
                    creating heightened demands on manufacturing facilities,
                    suppliers, distributors, retail establishments, and storage
                    facilities. Leveraging our widespread international reach
                    and extensive background in furnishing logistical and
                    shipping solutions to this field, {companyname} offers a
                    comprehensive solution to cater to your entire spectrum of
                    supply chain transportation requirements.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 events_full_box">
              <div className="events_single events_single_left" id="Fruits">
                <div className="event_info">
                  <h3>
                    <Link title> Fruits & Vegetables</Link>
                  </h3>
                  <div className="mt-4"></div>
                  <p>
                    Modern customers anticipate a consistent provision of fresh,
                    delectable, and aesthetically pleasing fruits and
                    vegetables. Ensuring the impeccable condition of these
                    fragile, perishable items during their journey from farm to
                    ultimate delivery point, and simultaneously maintaining
                    their flavor, crispness, texture, and fragrance,
                    necessitates swiftness, adaptability, and cutting-edge
                    temperature control technology.
                  </p>
                </div>
                <div className="event_banner">
                  <Link>
                    <img src="images/fruits.jpg" alt="" className="img-fluid" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-12 events_full_box">
              <div className="events_single" id="Food">
                <div className="event_banner">
                  <Link>
                    <img src="images/foods.jpg" alt="" className="img-fluid" />
                  </Link>
                </div>
                <div className="event_info">
                  <h3>
                    <Link title> Food & Beverages</Link>
                  </h3>
                  <div className="mt-4"></div>
                  <p>
                    In order to meet the global demand for delicious beverages,
                    producers and distributors must ensure the swift and
                    effective movement of these products. {companyname} provides
                    rapid and dependable transportation options to various
                    destinations. Therefore, regardless of the type of beverages
                    being conveyed, our specialists are here to offer the smooth
                    assistance essential for your fluid supply chain
                    requirements.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 events_full_box">
              <div className="events_single events_single_left" id="Chemicals">
                <div className="event_info">
                  <h3>
                    <Link title> Chemicals & Petrochemicals</Link>
                  </h3>
                  <div className="mt-4"></div>
                  <p>
                    {companyname} employs meticulous, exacting, and resilient
                    procedures to securely convey hazardous and potentially
                    harmful materials like chemicals and petrochemicals. As
                    proficient authorities in transporting perilous freight,{" "}
                    {companyname} guarantees the secure shipment of your cargo
                    while adhering to pertinent guidelines. Furthermore, we
                    stand ready to assist you in managing unforeseen
                    circumstances regardless of your shipping destination across
                    the world.
                  </p>
                </div>
                <div className="event_banner">
                  <Link>
                    <img
                      src="images/chemicals.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
