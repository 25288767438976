import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Sea() {
  return (
    <>
      <Header />
      <header className="header_inner about_page">
      <div className="intro_wrapper">
          <div className="container">  
            <div className="row">        
              <div className="col-sm-12 col-md-8 col-lg-8">
                <div className="intro_text">
                  <h1>Project Cargo</h1>
                  <div className="pages_links">
                    <Link to="/Home" title>Home</Link>
                    <Link title className="active">Project Cargo</Link>
                  </div>
                </div>
              </div>              
            </div>
          </div> 
        </div> 
        </header>
        <section className="blog_wrapper" id="courses_details_wrapper">
        <div className="container">  
          <div className="row">        
            <div className="col-12 col-sm-12 col-md-8 col-lg-8">
              <div className="courses_details">
                <div className="single-curses-contert">
                  <h2>Project Cargo</h2>
                  <div className="mt-4">
                   
                   
                  </div>
                  <div className="details-img-bxo">
                    <img src="images/project.jpg" alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="courses_tab_wrapper">  
                   
                  {/* Tab panes */}
                  <div className="tab_contents tab-content">
                    <div className="tab-pane fade in active show" id="information">
                     
                      <p>
                      Harnessing our specialized expertise and extensive know-how, we commit ourselves wholeheartedly to delivering optimal solutions tailored for handling hefty and unconventional cargo. Navigating the intricacies of out-of-gauge shipments with finesse, we draw upon our comprehensive understanding to ensure seamless operations. It's not just about managing oversized loads; it's about orchestrating a harmonious symphony of precision and logistics.
<br/><br/>
With a keen eye on detail and an arsenal of proficiency, we go beyond the ordinary to ensure that every aspect of transporting these exceptional cargoes is meticulously planned and flawlessly executed. Our approach extends to encompassing the provision of dedicated equipment precisely suited for the task at hand. Whether it's specialized cranes, custom-built transporters, or innovative loading techniques, we leave no stone unturned in our quest to ensure the smoothest journey possible for your valuable cargo.
<br/><br/>
Our commitment doesn't just lie in the physical handling of the cargo; it's a holistic dedication to meeting the unique demands and challenges posed by these extraordinary shipments. By seamlessly merging expertise, technology, and a finely tuned strategy, we endeavor to provide a seamless and efficient experience that instills confidence and trust. The world of heavy and out-of-gauge cargo may be complex, but with our unwavering dedication, it becomes a realm of possibilities waiting to be explored.


                      </p>
                     
                    </div>
                   
                   
                   
                  </div>
                </div> 
              </div>
            </div> {/*End Blog Siderbar Left*/}
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 blog_wrapper_right ">
              <div className="blog-right-items">
                <div className="courses_features widget_single">
                  <div className="items-title">
                    <h3 className="title">All Services</h3>
                  </div>
                  <div className="features_items">
                    <ul className="list-unstyled">
                      <li><Link to="/Air" title><i className="fa fa-plane" /> Air Freight </Link></li>
                      <li><Link to="/Sea" title><i className="fa fa-ship" /> Sea Freight </Link></li>
                      <li><Link to="/Road" title><i className="fa fa-truck" /> Road Freight </Link></li>
                      <li><Link to="/Customs" title><i className="fa fa-check-circle" /> Customs Clearance </Link></li>
                      <li><Link to="/Warehousing" title><i className="fa fa-warehouse" /> Warehousing </Link></li>
                      <li><Link to="/Project" title><i className="fa fa-box" /> Project Cargo </Link></li>

                      
                    </ul>
                  </div>
                  <img src="images/shapes/testimonial_2_shpe_2.png" alt="" className="courses_feaures_shpe" />
                </div>  
               
                <div className="archives widget_single">
                  <div className="items-title">
                    <h3 className="title">Trading Services</h3>
                  </div>
                  <div className="archives-items">
                    <ul className="list-unstyled">
                      <li><Link to="/Trading#Apparel" title>Apparel </Link></li>
                      <li><Link to="/Trading#Fruits" title>Fruits & Vegetables </Link></li>
                      <li><Link to="/Trading#Food" title>Food & Beverages </Link></li>
                      <li><Link to="/Trading#Chemicals" title>Chemicals & Petrochemicals </Link></li>
                     
                    </ul>
                  </div>
                </div>  
              </div>
            </div> {/* End Right Sidebar*/}
          </div>
        </div> 
      </section>
      <Footer />
    </>
  );
}
