import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Sea() {
  return (
    <>
      <Header />
      <header className="header_inner about_page">
        <div className="intro_wrapper">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-8 col-lg-8">
                <div className="intro_text">
                  <h1>Customs Clearance</h1>
                  <div className="pages_links">
                    <Link to="/Home" title>
                      Home
                    </Link>
                    <Link title className="active">
                      Customs Clearance
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="blog_wrapper" id="courses_details_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-8 col-lg-8">
              <div className="courses_details">
                <div className="single-curses-contert">
                  <h2>Customs Clearance</h2>
                  <div className="mt-4"></div>
                  <div className="details-img-bxo">
                    <img
                      src="images/customs.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="courses_tab_wrapper">
                  {/* Tab panes */}
                  <div className="tab_contents tab-content">
                    <div
                      className="tab-pane fade in active show"
                      id="information"
                    >
                      <p>
                        Integrated with the customs framework, we adeptly
                        document all necessary procedures directly from our
                        offices, ensuring a streamlined and expeditious process.
                        Our comprehensive support encompasses a range of steps
                        within the clearance procedure, encompassing:
                        <br />
                        <br />
                        Accurate determination of customs tariffs corresponding
                        to item descriptions.
                        <br />
                        Facilitation of the Import License preparation.
                        <br />
                        Expertise in compiling specialized customs
                        authorizations as required.
                        <br />
                        Skillful arrangement and submission of the customs
                        declaration.
                        <br />
                        Assisting throughout customs inspections to ensure
                        compliance.
                        <br />
                        Provision of comprehensive goods insurance coverage.
                        <br />
                        Efficient coordination of both goods pickup and
                        delivery.
                        <br />
                        <br />
                        Our interconnected approach, bolstered by our
                        technological integration with the customs system,
                        guarantees a seamless and efficient navigation of the
                        intricate customs landscape. We are your reliable
                        partner, dedicated to ensuring a smooth and hassle-free
                        experience throughout the entire customs clearance
                        process.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            {/*End Blog Siderbar Left*/}
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 blog_wrapper_right ">
              <div className="blog-right-items">
                <div className="courses_features widget_single">
                  <div className="items-title">
                    <h3 className="title">All Services</h3>
                  </div>
                  <div className="features_items">
                    <ul className="list-unstyled">
                      <li>
                        <Link to="/Air" title>
                          <i className="fa fa-plane" /> Air Freight{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Sea" title>
                          <i className="fa fa-ship" /> Sea Freight{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Road" title>
                          <i className="fa fa-truck" /> Road Freight{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Customs" title>
                          <i className="fa fa-check-circle" /> Customs Clearance{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Warehousing" title>
                          <i className="fa fa-warehouse" /> Warehousing{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Project" title>
                          <i className="fa fa-box" /> Project Cargo{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <img
                    src="images/shapes/testimonial_2_shpe_2.png"
                    alt=""
                    className="courses_feaures_shpe"
                  />
                </div>

                <div className="archives widget_single">
                  <div className="items-title">
                    <h3 className="title">Trading Services</h3>
                  </div>
                  <div className="archives-items">
                    <ul className="list-unstyled">
                      <li>
                        <Link to="/Trading#Apparel" title>
                          Apparel{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Trading#Fruits" title>
                          Fruits & Vegetables{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Trading#Food" title>
                          Food & Beverages{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Trading#Chemicals" title>
                          Chemicals & Petrochemicals{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>{" "}
            {/* End Right Sidebar*/}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
