import { Outlet, Link } from "react-router-dom";

import React, { useEffect, useState } from "react";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  return (
    <>
      <header className="header_inner about_page">
        {/* Preloader */}
         
        <div className="header_top">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-lg-12">
                <div className="info_wrapper">
                  <div className="contact_info">
                    <ul className="list-unstyled">
                      <li>
                        <i className="flaticon-phone-receiver" />
                        {companynumber}
                      </li>
                      <li>
                        <i className="flaticon-mail-black-envelope-symbol" />
                        {companyemail}
                      </li>
                    </ul>
                  </div>
                  <div className="contact_info">
                    <ul className="list-unstyled">
                      <li>
                        <Link to="/Privacy" style={{ color: "white" }}>
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link to="/Terms" style={{ color: "white" }}>
                          Terms
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="edu_nav">
          <div className="container">
          <nav className="navbar navbar-expand-md navbar-light bg-faded">
              <Link className="navbar-brand" to="/Home">
                <img
                  src="images/logo.png"
                  style={{ height: "100px" }}
                  alt="logo"
                />
              </Link>
              <div
                className="collapse navbar-collapse main-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav nav lavalamp ml-auto menu">
                  <li className="nav-item">
                    <Link to="/Home" className="nav-link ">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/About" className="nav-link">
                      About
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/Services" className="nav-link">
                      Services We Provide
                    </Link>
                    <ul className="navbar-nav nav mx-auto">
                      <li className="nav-item">
                        <Link to="/Air" className="nav-link">
                          Air Freight
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/Sea" className="nav-link">
                          Sea Freight
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/Road" className="nav-link">
                          Road Freight
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/Customs" className="nav-link">
                          Customs Clearance
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/Warehousing" className="nav-link">
                          Warehousing
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/Project" className="nav-link">
                          Project Cargo
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="#" className="nav-link dropdown_icon">
                          Trading Solutions
                        </Link>
                        <ul className="navbar-nav nav mx-auto">
                          <li className="nav-item">
                            <Link to="/Trading#Apparel" className="nav-link">
                              Apparel
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/Trading#Fruits" className="nav-link">
                            Fruits & Vegetables
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/Trading#Food" className="nav-link">
                            Food & Beverages
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/Trading#Chemicals" className="nav-link">
                            Chemicals & Petrochemicals
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link to="/Contact" className="nav-link">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mr-auto search_area ">
                <Link
                  to="/Getquote"
                  className="cart_btn"
                  style={{
                    background: "#162640",
                    fontWeight: 600,
                    borderRadius: 0,
                    color: "#ffffff",
                    display: "inline-block",
                    fontSize: "14px",
                    textTransform: "uppercase",
                    textDecoration: "none",
                    height: "36px",
                    lineHeight: "36px",
                    padding: "0 20px",
                    WebkitTransition: "all 0.3s ease-in-out",
                    transition: "all 0.3s ease-in-out",
                  }}
                >
                  Request For Quote
                </Link>
              </div>
            </nav>
          </div> 
        </div>
        
      </header>
    </>
  );
};

export default Header;
