import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  return (
    <> 
       <footer>
        <div className="container">
         
          <div className="footer_top">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4">
                <div className="footer_single_col footer_intro">
                  <img src="images/logo.png" alt="" style={{ height: "100px" }} className="f_logo" />
                  <p>
                   
Through our well-honed expertise and extensive network of industry connections, we possess a wealth of resources that empower us to offer our clients the most competitive pricing options available within the market landscape.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-2">
                <div className="footer_single_col">
                  <h3>Useful Links</h3>
                  <ul className="location_info quick_inf0">
                    <li><Link to="/Home">Home</Link></li>
                    <li><Link to="/About">About</Link></li>
                    <li><Link to="/Freight">Freight Services</Link></li>
                    <li><Link to="/Trading">Trading Services</Link></li>
                    <li><Link to="/Contact">Contact</Link></li>
                    <li><Link to="/Getquote">Getquote</Link></li>
                    <li><Link to="/Privacy">Privacy Policy</Link></li>
                    <li><Link to="/Terms">Terms</Link></li>

                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-2">
                <div className="footer_single_col information">
                  <h3>Our Freight Services</h3>
                  <ul className="quick_inf0">
                  <li>
                        <Link to="/Air" >
                          Air Freight
                        </Link>
                      </li>
                      <li>
                        <Link to="/Sea" >
                          Sea Freight
                        </Link>
                      </li>

                      <li>
                        <Link to="/Road" >
                          Road Freight
                        </Link>
                      </li>
                      <li>
                        <Link to="/Customs" >
                          Customs Clearance
                        </Link>
                      </li>
                      <li>
                        <Link to="/Warehousing" >
                          Warehousing
                        </Link>
                      </li>
                      <li>
                        <Link to="/Project" >
                          Project Cargo
                        </Link>
                      </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="footer_single_col contact">
                  <h3>Contact Us</h3>
                  <p>
                    Fell free to get in touch us via Phone or send us a message.
                  </p>
                  <div className="contact_info">
                    <span>{companynumber}</span>
                    <span className="email">{companyemail}</span>
                    <br/> <br/>
                    <span>{companyaddress}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="round_shape">
          <span className="shape_1" />
          <span className="shape_2" />
          <span className="shape_3" />
          <span className="shape_4" />
          <span className="shape_5" />
          <span className="shape_6" />
        </div>
        <img src="images/shapes/footer_bg_shpe_1.png" alt="" className="shapes1_footer" />
      </footer>
     
    </>
  );
};

export default Footer;
