import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Sea() {
  return (
    <>
      <Header />
      <header className="header_inner about_page">
        <div className="intro_wrapper">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-8 col-lg-8">
                <div className="intro_text">
                  <h1>Air Freight</h1>
                  <div className="pages_links">
                    <Link to="/Home" title>
                      Home
                    </Link>
                    <Link title className="active">
                      Air Freight
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="blog_wrapper" id="courses_details_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-8 col-lg-8">
              <div className="courses_details">
                <div className="single-curses-contert">
                  <h2>Air Freight</h2>
                  <div className="mt-4"></div>
                  <div className="details-img-bxo">
                    <img src="images/air.webp" alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="courses_tab_wrapper">
                  {/* Tab panes */}
                  <div className="tab_contents tab-content">
                    <div
                      className="tab-pane fade in active show"
                      id="information"
                    >
                      <p>
                        We are swift and adaptable in meeting your air freight
                        demands, catering to a broad spectrum of needs, from
                        urgent Next Flight Out services to seamless same-day
                        options, spanning across both domestic and international
                        territories. Our specialized offerings extend to
                        tailor-made solutions like On-Board Couriers, ensuring
                        your unique requirements are fulfilled. Our expertise,
                        extensive experience, and global presence stand poised
                        to bolster your business operations.
                        <br />
                        <br />
                        Through strategic collaboration with prominent
                        international carriers renowned for their expansive
                        capacity and widespread influence, we craft personalized
                        logistics strategies that align precisely with your
                        stipulations, all while maintaining budget-friendly
                        costs.
                        <br />
                        <br />
                        Are you contemplating whether freight forwarding is the
                        optimal route for your business? Let us clarify. Our
                        proactive approach involves pre-purchasing and reserving
                        airline capacity well in advance, empowering us to
                        readily accommodate your time-sensitive same-day freight
                        shipping needs. Our proficiency encompasses a
                        comprehensive spectrum of goods transportation and our
                        reach extends to thousands of destinations across
                        hundreds of countries worldwide. Not only do we
                        facilitate seamless collection and delivery, but we also
                        adeptly navigate the intricacies of essential customs
                        and carnet paperwork.
                        <br />
                        <br />
                        Our commitment spans both international and domestic
                        domains, positioning us as a stalwart in air freight
                        forwarding. We bring forth an impressive roster of
                        top-tier international carriers, underlining our
                        dedication to providing you with comprehensive solutions
                        tailored to your distinct requisites.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            {/*End Blog Siderbar Left*/}
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 blog_wrapper_right ">
              <div className="blog-right-items">
                <div className="courses_features widget_single">
                  <div className="items-title">
                    <h3 className="title">All Services</h3>
                  </div>
                  <div className="features_items">
                    <ul className="list-unstyled">
                      <li>
                        <Link to="/Air" title>
                          <i className="fa fa-plane" /> Air Freight{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Sea" title>
                          <i className="fa fa-ship" /> Sea Freight{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Road" title>
                          <i className="fa fa-truck" /> Road Freight{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Customs" title>
                          <i className="fa fa-check-circle" /> Customs Clearance{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Warehousing" title>
                          <i className="fa fa-warehouse" /> Warehousing{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Project" title>
                          <i className="fa fa-box" /> Project Cargo{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <img
                    src="images/shapes/testimonial_2_shpe_2.png"
                    alt=""
                    className="courses_feaures_shpe"
                  />
                </div>

                <div className="archives widget_single">
                  <div className="items-title">
                    <h3 className="title">Trading Services</h3>
                  </div>
                  <div className="archives-items">
                    <ul className="list-unstyled">
                      <li>
                        <Link to="/Trading#Apparel" title>
                          Apparel{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Trading#Fruits" title>
                          Fruits & Vegetables{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Trading#Food" title>
                          Food & Beverages{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Trading#Chemicals" title>
                          Chemicals & Petrochemicals{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>{" "}
            {/* End Right Sidebar*/}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
