import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function About() {
  return (
    <>
      <Header />
      <header className="header_inner about_page">
        <div className="intro_wrapper">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-8 col-lg-8">
                <div className="intro_text">
                  <h1>About Us</h1>
                  <div className="pages_links">
                    <Link to="/Home" title>
                      Home
                    </Link>
                    <Link title className="active">
                      About Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </header>

      <section className="about_us">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
              <div className="about_title">
                <span>About Us</span>
                <h2>From Here to Everywhere, We've Got You Covered.</h2>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 p-0">
              <div className="banner_about">
                <img
                  src="images/about1.jpg"
                  alt=""
                  style={{ height: "420px" }}
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row about_content_wrapper">
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 p-0">
              <div className="about_banner_down" style={{ marginTop: "80px" }}>
                <img src="images/about2.jpg" alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
              <div className="about_content">
                <p>
                  Through our well-honed expertise and extensive network of
                  industry connections, we possess a wealth of resources that
                  empower us to offer our clients the most competitive pricing
                  options available within the market landscape. Our commitment
                  extends beyond conventional approaches; we are relentless in
                  our pursuit of innovative strategies to curtail expenses and
                  bolster revenues for our valued customers.
                  <br />
                </p>
              </div>
            </div>
          </div>
          <p className="mt-4">
            At the core of our service portfolio lies a comprehensive suite of
            solutions, encompassing both Freight Forwarding and Trading. With
            our profound understanding of the logistics realm, we expertly
            navigate the complexities of moving goods across borders and
            continents. Simultaneously, our trading expertise opens avenues for
            strategic partnerships, sourcing, and distribution, amplifying your
            potential for growth.
            <br />
            Driven by a resolute dedication to your success, we blend our
            insightful market knowledge with a penchant for optimization,
            ensuring that your supply chain flourishes and your commercial
            ventures thrive. Our commitment to delivering results goes beyond
            just a promise; it's a reflection of our unwavering commitment to
            serve as your steadfast partner, enabling your business to not only
            survive but to truly thrive in the dynamic and competitive global
            marketplace.
          </p>
        </div>
      </section>

      <Footer />
    </>
  );
}
