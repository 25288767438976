import Headerhome from "../components/Headerhome";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  return (
    <>
      <Headerhome />
     
        {/* End Login Signup Option */}
        <section className="cources_highlight">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <div className="latest_blog_carousel">
                  <div className="single_item single_item_first">
                    <div className="blog-img">
                      <Link to="/Air"><img src="images/air.webp"   style={{height:'300px'}} alt="" className="img-fluid" /></Link>
                    </div>
                    <div className="blog_title">
                      <span>Services</span>
                      <h3><Link to="/Air" title>Air Freight Service</Link></h3>
                      <p>
                         We are swift and adaptable in meeting your air freight
                        demands, catering to a broad spectrum of needs, from
                        urgent Next Flight Out services to seamless same-day
                        options, spanning across both domestic and international
                        territories....
                      </p>
                    </div>
                  </div>
                  <div className="single_item single_item_center">
                    <div className="blog-img">
                      <Link to="/Sea" title><img src="images/sea.jpg" alt="" className="img-fluid" /></Link>
                    </div>
                    <div className="blog_title">
                      <span>Services</span>
                      <h3><Link to="/Sea" title>Sea Freight</Link></h3>
                      <p>
                        Collaborating with renowned ocean carriers, we present a
                        comprehensive array of options that grant you full
                        visibility and flexibility. From Specialized Equipment
                        Shipping designed to accommodate unique cargo, to Full
                        Container Load (FCL), Less than Container Load (LCL),
                        and our innovative Hybrid LCL offerings, we extend our
                        services to a multitude of destinations and alliances....
                      </p>
                    </div>
                  </div>
                  <div className="single_item single_item_last">
                    <div className="blog-img">
                      <Link to="/Road" title><img src="images/road.jpg" style={{height:'300px'}} alt="" className="img-fluid" /></Link>
                    </div>
                    <div className="blog_title">
                      <span>Services</span>
                      <h3><Link to="/Road" title>Road Freight</Link></h3>
                      <p>
                         Our extensive road networks stand as a testament to
                        well-established and intricately woven pathways that
                        crisscross regions across the India....
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="about_us">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
              <div className="about_title">
                <span>About Us</span>
                <h2>From Here to Everywhere, We've Got You Covered.</h2>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 p-0">
              <div className="banner_about">
                <img
                  src="images/about1.jpg"
                  alt=""
                  style={{ height: "420px" }}
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row about_content_wrapper">
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 p-0">
              <div className="about_banner_down" style={{ marginTop: "80px" }}>
                <img src="images/about2.jpg" alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
              <div className="about_content">
                <p>
                  Through our well-honed expertise and extensive network of
                  industry connections, we possess a wealth of resources that
                  empower us to offer our clients the most competitive pricing
                  options available within the market landscape. Our commitment
                  extends beyond conventional approaches; we are relentless in
                  our pursuit of innovative strategies to curtail expenses and
                  bolster revenues for our valued customers.
                  <br />
                </p>
                <Link
                  to="/About"
                  className="cart_btn"
                  style={{
                    background: "#162640",
                    fontWeight: 600,
                    borderRadius: 0,
                    color: "#ffffff",
                    display: "inline-block",
                    fontSize: "14px",
                    textTransform: "uppercase",
                    textDecoration: "none",
                    height: "36px",
                    lineHeight: "36px",
                    padding: "0 20px",
                    WebkitTransition: "all 0.3s ease-in-out",
                    transition: "all 0.3s ease-in-out",
                  }}
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
         
        </div>
      </section>
        {/* End Popular Courses Highlight */}
        <section className="popular_courses">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <div className="sub_title">
                  <h2>Our Freight Services</h2>
                  <p>
                   
                  </p>
                </div>
                {/* ends: .section-header */}
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                <div className="single-courses">
                  <div className="courses_banner_wrapper">
                    <div className="courses_banner">
                      <Link to="/Customs"><img src="images/customs.jpg" alt="" className="img-fluid" /></Link>
                    </div>
                    
                  </div>
                  <div className="courses_info_wrapper">
                    <div className="courses_title">
                      <h3><Link to="/Customs">Customs Clearance</Link></h3>
                     
                    </div>
                    <div className="courses_info">
                     
                      <Link to="/Customs" className="cart_btn">Learn More</Link>
                    </div>
                  </div>
                </div>
                {/* Ends: .single courses */}
              </div>
              {/* Ends: . */}
              <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                <div className="single-courses">
                  <div className="courses_banner_wrapper">
                    <div className="courses_banner">
                      <Link to="/Warehousing"><img src="images/warehousing.jpg" alt="" className="img-fluid" /></Link>
                    </div>
                    
                  </div>
                  <div className="courses_info_wrapper">
                    <div className="courses_title">
                      <h3><Link to="/Warehousing">Warehousing</Link></h3>
                     
                    </div>
                    <div className="courses_info">
                     
                      <Link to="/Warehousing" className="cart_btn">Learn More</Link>
                    </div>
                  </div>
                </div>
                {/* Ends: .single courses */}
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                <div className="single-courses">
                  <div className="courses_banner_wrapper">
                    <div className="courses_banner">
                      <Link to="/Project"><img src="images/project.jpg" alt="" className="img-fluid" /></Link>
                    </div>
                    
                  </div>
                  <div className="courses_info_wrapper">
                    <div className="courses_title">
                      <h3><Link to="/Project">Project Cargo</Link></h3>
                     
                    </div>
                    <div className="courses_info">
                     
                      <Link to="/Project" className="cart_btn">Learn More</Link>
                    </div>
                  </div>
                </div>
                {/* Ends: .single courses */}
              </div>
              {/* Ends: . */}
             
            </div>
          </div>
        </section>

        <section className="blog">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <div className="sub_title">
                  <h2>Our Trading Services</h2>
                  <p>
                  We have earned the position of a reliable transportation
                    collaborator for numerous esteemed global enterprises,
                    conscientiously transporting numerous cargoes and
                    commodities with unparalleled diligence. Our services
                    encompass comprehensive solutions for trading needs.
                  </p>
                </div>
                {/* ends: .section-header */}
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                <div className="single_item single_item_first">
                  <div className="blog-img">
                    <Link to="/Trading" title><img src="images/apparel.jpg" style={{height:'220px'}} alt="" className="img-fluid" /></Link>
                  </div>
                  <div className="blog_title">
                    
                    <h3>
                      <Link to="/Trading"  title>Apparel</Link>
                    </h3>
                    <div className="post_bloger">
                     
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                <div className="single_item single_item_first">
                  <div className="blog-img">
                    <Link to="/Trading" title><img src="images/fruits.jpg" style={{height:'220px'}} alt="" className="img-fluid" /></Link>
                  </div>
                  <div className="blog_title">
                    
                    <h3>
                      <Link to="/Trading"  title>Fruits & Vegetables</Link>
                    </h3>
                    <div className="post_bloger">
                     
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                <div className="single_item single_item_first">
                  <div className="blog-img">
                    <Link to="/Trading" title><img src="images/foods.jpg" style={{height:'220px'}} alt="" className="img-fluid" /></Link>
                  </div>
                  <div className="blog_title">
                    
                    <h3>
                      <Link to="/Trading"  title>Food & Beverages</Link>
                    </h3>
                    <div className="post_bloger">
                     
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                <div className="single_item single_item_first">
                  <div className="blog-img">
                    <Link to="/Trading" title><img src="images/chemicals.jpg" style={{height:'220px'}} alt="" className="img-fluid" /></Link>
                  </div>
                  <div className="blog_title">
                    
                    <h3>
                      <Link to="/Trading"  title>Chemicals & Petrochemicals</Link>
                    </h3>
                    <div className="post_bloger">
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Blog  */}

      <Footer />
    </>
  );
}
