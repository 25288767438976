import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Sea() {
  return (
    <>
      <Header />
      <header className="header_inner about_page">
        <div className="intro_wrapper">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-8 col-lg-8">
                <div className="intro_text">
                  <h1>Sea Freight</h1>
                  <div className="pages_links">
                    <Link to="/Home" title>
                      Home
                    </Link>
                    <Link title className="active">
                      Sea Freight
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="blog_wrapper" id="courses_details_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-8 col-lg-8">
              <div className="courses_details">
                <div className="single-curses-contert">
                  <h2>Sea Freight</h2>
                  <div className="mt-4"></div>
                  <div className="details-img-bxo">
                    <img src="images/sea.jpg" alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="courses_tab_wrapper">
                  {/* Tab panes */}
                  <div className="tab_contents tab-content">
                    <div
                      className="tab-pane fade in active show"
                      id="information"
                    >
                      <p>
                        Collaborating with renowned ocean carriers, we present a
                        comprehensive array of options that grant you full
                        visibility and flexibility. From Specialized Equipment
                        Shipping designed to accommodate unique cargo, to Full
                        Container Load (FCL), Less than Container Load (LCL),
                        and our innovative Hybrid LCL offerings, we extend our
                        services to a multitude of destinations and alliances.
                        Through our vessels equipped with GPS tracking and
                        augmented by third-party data integration, you're
                        assured real-time updates on the status of your ocean
                        freight.
                        <br />
                        <br />
                        Acknowledging the pivotal role of ocean freight, we
                        approach each shipment with meticulous attention, aiming
                        to optimize both space and equipment utilization. By
                        partnering with carrier experts who have honed their
                        expertise, we strive to enhance the efficiency of your
                        shipments. Rest assured, your cargo is entrusted to
                        capable hands, as we recognize the unforgiving nature of
                        this industry where precision is paramount. Businesses
                        rely on the steadfast commitment of timely and effective
                        shipping procedures, and we wholeheartedly understand
                        the zero-tolerance stance for errors in this field.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            {/*End Blog Siderbar Left*/}
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 blog_wrapper_right ">
              <div className="blog-right-items">
                <div className="courses_features widget_single">
                  <div className="items-title">
                    <h3 className="title">All Services</h3>
                  </div>
                  <div className="features_items">
                    <ul className="list-unstyled">
                      <li>
                        <Link to="/Air" title>
                          <i className="fa fa-plane" /> Air Freight{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Sea" title>
                          <i className="fa fa-ship" /> Sea Freight{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Road" title>
                          <i className="fa fa-truck" /> Road Freight{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Customs" title>
                          <i className="fa fa-check-circle" /> Customs Clearance{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Warehousing" title>
                          <i className="fa fa-warehouse" /> Warehousing{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Project" title>
                          <i className="fa fa-box" /> Project Cargo{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <img
                    src="images/shapes/testimonial_2_shpe_2.png"
                    alt=""
                    className="courses_feaures_shpe"
                  />
                </div>

                <div className="archives widget_single">
                  <div className="items-title">
                    <h3 className="title">Trading Services</h3>
                  </div>
                  <div className="archives-items">
                    <ul className="list-unstyled">
                      <li>
                        <Link to="/Trading#Apparel" title>
                          Apparel{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Trading#Fruits" title>
                          Fruits & Vegetables{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Trading#Food" title>
                          Food & Beverages{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Trading#Chemicals" title>
                          Chemicals & Petrochemicals{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>{" "}
            {/* End Right Sidebar*/}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
